<template>
	<div>
		<div :class="['my-flex-spa-ar home-nav-wrap', {'mobile-nav-height':mobileNavHeight}]">
			<div class="logo-wrap">
				<img class="logo-img" :src="logo" alt="">
			</div>
			<div class="my-flex" id="menus-position-wrap">
				<div>
				
				</div>
				<Menu @on-select="onRouterChange" v-if=" menusVisible " mode="horizontal" :theme="theme1"
				      active-name="1">
					<MenuItem name="1">
						<Icon type="md-filing" />
						首页
					</MenuItem>
					<MenuItem name="2">
						<Icon custom="iconfont icon-mars_"/>
						未来火星计划
					</MenuItem>
					<MenuItem name="3">
						<Icon custom="iconfont icon-question"/>
						F&Q
					</MenuItem>
				</Menu>
				<div v-if=" !menusVisible "  class="my-flex" style="justify-content: flex-end">
					<Dropdown @on-click="onSelectMobileMenus">
						<Icon size="40" color="#fff" type="md-menu"></Icon>
						<DropdownMenu slot="list">
							<DropdownItem name="1">
								<Icon type="md-filing" />
								首页</DropdownItem>
							<DropdownItem name="2">
								<Icon custom="iconfont icon-mars_"/>未来火星计划</DropdownItem>
							<DropdownItem name="3">
								<Icon custom="iconfont icon-question"/>F&Q</DropdownItem>
						</DropdownMenu>
					</Dropdown>
				</div>
			</div>
		</div>
		<div style="height: 100px;width: 100%"></div>
		<el-table v-loading="loading" :border="true" :data="table_list">
			<el-table-column
				prop="title"
			>
				<template slot="header" slot-scope="scope">
					<div style="color: #ffffff">标记点</div>
					<div style="color: #ffffff">M.loc</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="name"
			>
				<template slot="header" slot-scope="scope">
					<div style="color: #ffffff">所有人</div>
					<div style="color: #ffffff">OWNER</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="location"
			>
				<template slot="header" slot-scope="scope">
					<div style="color: #ffffff">坐标</div>
					<div style="color: #ffffff">Lng&Lat</div>
				</template>
			</el-table-column>
			<el-table-column
				prop="created_at"
			>
				<template slot="header" slot-scope="scope">
					<div style="color: #ffffff">纪念日</div>
					<div style="color: #ffffff">DATE</div>
				</template>
			</el-table-column>
			<template slot="append">
				<div style="text-align: center;padding: 15px 0">
					<el-pagination
						background
						@current-change="sizeChnage"
						layout="prev, pager, next"
						:page-count="totalPage">
					</el-pagination>
				</div>
			</template>
		</el-table>
		<!--底部大图片-->
		<div style="max-width: 100%;height: auto">
			<img style="width: 100%;height: 100%" :src="thumb" alt="">
		</div>
	</div>
</template>

<script>
export default {
	name: "List",
	data(){
		return {
			table_list: [],
			mobileNavHeight: !1,
			logo: localStorage.getItem('logo'),
			menusVisible: !0,
			theme1: 'dark',
			page: 1,
			totalPage: 1,
			loading: !0,
			thumb: ''
		}
	},
	mounted() {
		this.getScreen()
		this.loadList()
		this._loadCopyRight()
	},
	methods: {
		_loadCopyRight() {
			this.request({
				url: '/api/getSetting'
			}).then(res => {
				console.log(res, '=====getSetting====');
				if (0 == res.code) {
					this.thumb = res.data.thumb
				}
			})
		},
		onSelectMobileMenus(e){
			console.log(e,'====我是触发===');
			if (2==e){
				this.$router.push({
					path: '/about'
				})
			}else if (3==e){
				this.$router.push({
					path: '/question'
				})
			}else if (1==e){
				this.$router.push({
					path: '/'
				})
			}
		},
		/**
		 * 路由切换
		 */
		onRouterChange(e){
			console.log(e);
			if (2==e){
				this.$router.push({
					path: '/about'
				})
			}else if (3==e){
				this.$router.push({
					path: '/question'
				})
			}else if (1==e){
				this.$router.push({
					path: '/'
				})
			}
		},
		getScreen() {
			let w = document.documentElement.offsetWidth || document.body.offsetWidth;
			if (w < 1000) { // 手机
				this.mobileNavHeight = !0
				this.menusVisible = !1
			}
		},
		sizeChnage(e){
			console.log(e);
			this.page = e
			this.loadList()
			
		},
		/**
		 * 加载花名册
		 */
		loadList(){
			this.loading = !0
			this.request({
				url: '/api/getCoordinate_hmc',
				params: {
					page: this.page
				}
			}).then(res=>{
				this.loading = !1
				this.totalPage = res.data.last_page
				console.log(res.data.last_page,'======我是分页数====');
				if (res.data.data.length>0){
					this.totalPage = res.data.last_page
					const _checkStr = (arg1, arg2)=>{
						let temp1 = ''
						let temp2 = ''
						// arg1: 纬度
						// arg2: 经度
						// console.log(arg1, arg2);
						if (arg2<0){
							temp1 = `${Math.abs(arg2)}E`
						}else{
							temp1 = `${arg2}W`
						}
						if (arg1<0){
							temp2 = `${Math.abs(arg1)}S`
						}else{
							temp2 = `${arg1}N`
						}
						return `${temp2}, ${temp1}`
					}
					
					let tempList = res.data.data.map(item=>{
						return {
							title: item.title,
							name: item.name,
							created_at: item.mark,
							// location: `${item.latitude},${item.longitude}`
							location: _checkStr(Number(item.latitude), Number(item.longitude))
						}
					})
					this.table_list = tempList
					this.loading = !1
				}
				console.log(res,'======花名册数据=====');
			})
		},
	}
}
</script>

<style>
	#menus-position-wrap .ivu-menu-dark{
		color: #ffffff!important;
		background-color: #000000!important;
	}
	.el-table,
	.el-table__expanded-cell {
		background-color: transparent !important;
		color: #ffffff;
	}
	.el-table th,
	.el-table tr,
	.el-table td {
		background-color: transparent !important;
		color: #ffffff;
	}
</style>

<style lang="scss" scoped>
	@import "@/style/list.scss";
</style>
